* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    overflow-x: hidden;
    max-width: 100%;
}

:root {
    --white: #FFFFFF;
    --aqua: #4FCCC6;
    --dark-grey: #2B2B2B;
    --card-drop-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);
}

body {
    background-color: var(--dark-grey);
    color: var(--white);
}
