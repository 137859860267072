.snooze_container{
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 2;
  background: #20193A;
  border-radius: 8px;
  margin: 0 2.5rem;
}

.snooze_layout{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 32px;
}

.snooze_countdown{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.snooze_countdown > p{
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;
}

.snooze_button{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;
}