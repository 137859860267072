.container_layout {
  display: grid; 
  grid-template-columns: 25vw 75vw;
  grid-template-rows: 100vh;
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."; 
  height: 100vh;
  width: 100%;
}
.stream_container {
  display: grid; 
  grid-template-columns: 77vw;
  grid-template-rows: 70vh 30vh;
  gap: 0px 0px; 
  grid-template-areas: 
    "."
    "."; 
  overflow-x: auto;
}