.player {
    position: absolute;
    width: 76vw;
    height: 100vh;
}

.promoGif{
    position: absolute;
    width: 76vw;
    height:70%;
    z-index: 1;
}

.dialog-gif{
    object-fit: contain;
    user-select: text;
    position: fixed !important;
    box-sizing: border-box !important;
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    inset: 0px !important;
    margin: 0px !important;
}

.dialog-gif > img {
    width: 100%;
    height: 100%;
}