.clock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px 40px;
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 2;
}

.clock__date {
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
}

.clock__time {
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
}
