.card {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  transition: 400ms ease-in-out;
  cursor: pointer;
}

.card--active {
  background-color: var(--aqua);
  color: var(--dark-grey);
  margin-right: 0;
  border-radius: 0px 4px 4px 0px;
  width: 100%;
}

.card:hover {
  background-color: var(--aqua);
  color: var(--dark-grey);
  margin-right: 0;
  width: 100%;
  border-radius: 0px 4px 4px 0px;
}

.card svg {
  fill: var(--white);
}

.card--active svg,
.card:hover svg {
  fill: var(--dark-grey);
}

.card__content {
  width: 100%;
  display: flex;
  gap: 18px;
}

.card__picture {
  padding-left: 5px;
}

.card__picture > img {
  width: 147px;
  height: 90px;
  border-radius: 4px;
  object-fit: cover;
}

.card__title {
  /* the below was added to limit the line count to 2 */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  font-weight: bold;
  font-size: 24px;
  font-size: 1vw;
  line-height: 28px;
  margin-top: 10px;
}

.card__time {
  white-space: nowrap;
  font-weight: 300;
  font-size: 16px;
  font-size: 1vw;
  line-height: 21px;
}

.card--disabled{
  opacity: 0.2;
  pointer-events: none;
}