.events {
    position: relative;
    padding: 16px 0px 16px 10px;
    width: 100%;
    height: 30vh;
}

.events__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
}

.events__carousel {
    margin-top: 10px;
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    overflow-y: hidden;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.events__carousel::-webkit-scrollbar {
    display: none;
}

.event__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
}

.event,
.disabled_event {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 12px 12px 15px 12px;
    width: 244px;
    height: 145px;
    flex: 0 0 auto;
    overflow: unset;
}

.event:hover {
    cursor: pointer;
}

.disabled_event {
    opacity: 0.2;
}

.event__picture > img {
    width: 220px;
    height: 80%;
    object-fit: cover;
    border-radius: 4px;
}

.event__name {
    /* the below was added to limit the line count to 3 */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    word-wrap: break-word;
    font-weight: bold;
    font-size: 1vw;
    line-height: 21px;
    margin: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.event__ratings {
    position: absolute;
    z-index: 1;
}

.event__thumbs_up {
    float: left;
    border-right: 4px solid white;
    padding-right: 8px;
    cursor: pointer;
}

.event__thumbs_down {
    float: left;
    margin-left: 8px;
    cursor: pointer;
}
